import revive_payload_client_H7VXuK1pgN from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/node_modules/.pnpm/nuxt@3.7.3_@types+node@16.18.52_eslint@8.49.0_typescript@5.2.2_vue-tsc@1.8.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_QRb50uEcB6 from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/node_modules/.pnpm/nuxt@3.7.3_@types+node@16.18.52_eslint@8.49.0_typescript@5.2.2_vue-tsc@1.8.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_30lVjCBsfZ from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/node_modules/.pnpm/nuxt@3.7.3_@types+node@16.18.52_eslint@8.49.0_typescript@5.2.2_vue-tsc@1.8.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_dY6IqQDXxA from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/node_modules/.pnpm/nuxt@3.7.3_@types+node@16.18.52_eslint@8.49.0_typescript@5.2.2_vue-tsc@1.8.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/.nuxt/components.plugin.mjs";
import prefetch_client_xrY6bnjsk7 from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/node_modules/.pnpm/nuxt@3.7.3_@types+node@16.18.52_eslint@8.49.0_typescript@5.2.2_vue-tsc@1.8.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_4DBPBsFYk0 from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/node_modules/.pnpm/nuxt@3.7.3_@types+node@16.18.52_eslint@8.49.0_typescript@5.2.2_vue-tsc@1.8.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_H7VXuK1pgN,
  unhead_QRb50uEcB6,
  router_30lVjCBsfZ,
  payload_client_dY6IqQDXxA,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xrY6bnjsk7,
  chunk_reload_client_4DBPBsFYk0
]