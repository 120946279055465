import { default as consentV1lY64eYOPBgMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/consentV1.vue?macro=true";
import { default as data_sharing_consent_v2_465f7SgUVwrCeMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/data_sharing_consent_v2.5.vue?macro=true";
import { default as data_sharing_consent_v2_466aeVxBRUqGXMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/data_sharing_consent_v2.6.vue?macro=true";
import { default as dwg_basic_data_set_v1_460_465EhG3Q8ssbZMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg_basic_data_set_v1.0.5.vue?macro=true";
import { default as dwg_basic_data_set_v1_460_466Zzzjw8hXZ0Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg_basic_data_set_v1.0.6.vue?macro=true";
import { default as dwg_basic_data_set_v3_462_462blSf86YKLGBLMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg_basic_data_set_v3.2.2bl.vue?macro=true";
import { default as dwg_basic_data_set_v5bOsHG7N5B5Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg_basic_data_set_v5.vue?macro=true";
import { default as dwg_comi_backIbEptuN9QWMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg_comi_back.vue?macro=true";
import { default as dwg_comi_neckNwJHX6UHhOMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg_comi_neck.vue?macro=true";
import { default as dwg_eq5dJvcpV81RswMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg_eq5d.vue?macro=true";
import { default as dwgzKOH9Gl7ALMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg.vue?macro=true";
import { default as eprd1tWqYIDh2rMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/eprd.vue?macro=true";
import { default as basic_45datatn0USC7S9AMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/mint/basic-data.vue?macro=true";
import { default as consent_45plain_45mintHI59IQgp6EMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/mint/consent-plain-mint.vue?macro=true";
import { default as template_dwg_ndiv6oW9gOPIqMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/template_dwg_ndi.vue?macro=true";
import { default as template_dwg_odi4Q3pjXe9MXMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/template_dwg_odi.vue?macro=true";
import { default as consent1ybBrrAbOvMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/consent.vue?macro=true";
import { default as custom_45color_45themeMmI8AxQ7mmMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/custom-color-theme.vue?macro=true";
import { default as custom_45componentsj3xaeHb185Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/custom-components.vue?macro=true";
import { default as emphasize_45optional_45itemsRtMqvwyIgWMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/emphasize-optional-items.vue?macro=true";
import { default as paginatedremsteDDqAMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/paginated.vue?macro=true";
import { default as scroll_45to_45invalidmWLtYHN0qeMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/scroll-to-invalid.vue?macro=true";
import { default as stress_45testzRGGTee3TyMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/stress-test.vue?macro=true";
import { default as themingeoBUZ1uP3WMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/theming.vue?macro=true";
import { default as attachment4P2pCD9vCUMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/attachment.vue?macro=true";
import { default as booleanrOq71hBKUYMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/boolean.vue?macro=true";
import { default as choice44gXjcmxnWMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/choice.vue?macro=true";
import { default as date_45timeu967s2OSDuMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/date-time.vue?macro=true";
import { default as dateBGhwBwXQDiMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/date.vue?macro=true";
import { default as decimalDyHvlm5aOyMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/decimal.vue?macro=true";
import { default as displayTrdSc3LMOcMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/display.vue?macro=true";
import { default as grouparhhRS3lrOMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/group.vue?macro=true";
import { default as implant_45tableFJdT2Qi106Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/implant-table.vue?macro=true";
import { default as integerCUeC19wrHFMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/integer.vue?macro=true";
import { default as open_45choicent3fLxIuDuMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/open-choice.vue?macro=true";
import { default as quantitykqN1BPmGj6Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/quantity.vue?macro=true";
import { default as referencegfM9GZ1bE9Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/reference.vue?macro=true";
import { default as spineXOBzV2DT0fMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/spine.vue?macro=true";
import { default as stringoQZSeo3WMHMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/string.vue?macro=true";
import { default as summarydNrpH7Be5cMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/summary.vue?macro=true";
import { default as text3iH6RsAIeeMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/text.vue?macro=true";
import { default as timeIXiRpYOeVvMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/time.vue?macro=true";
import { default as urlJ2z8uaLUPhMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/url.vue?macro=true";
import { default as cqf_45expressionS0Obap5fzZMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/cqf-expression.vue?macro=true";
import { default as durationonvWD9TtyBMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/duration.vue?macro=true";
import { default as enable_45when_45expressionXXchaEeVgcMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/enable-when-expression.vue?macro=true";
import { default as entry_45formatK5bhnFn3k6Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/entry-format.vue?macro=true";
import { default as hiddenT8S7VvHov5Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/hidden.vue?macro=true";
import { default as implant_45no_45repeats2dSctver77Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/implant-no-repeats.vue?macro=true";
import { default as implant_45with_45response1noFYn3pU3Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/implant-with-response.vue?macro=true";
import { default as implant5KX8yK1hD4Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/implant.vue?macro=true";
import { default as column_45positionUDrXTaAMeDMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/layout/column-position.vue?macro=true";
import { default as maxValue1ZSwiwI0ccMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/maxValue.vue?macro=true";
import { default as minValueR2d4rnKQA1Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/minValue.vue?macro=true";
import { default as questionnaire_45constraintHVNxF699dDMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/questionnaire-constraint.vue?macro=true";
import { default as Help_45Buttonpb8BMDlYQYMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/questionnaire-itemControl/Help-Button.vue?macro=true";
import { default as Partial_45DateAYhKogg8uMMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/questionnaire-itemControl/Partial-Date.vue?macro=true";
import { default as SlideraqAmpUsrY8Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/questionnaire-itemControl/Slider.vue?macro=true";
import { default as questionnaire_45optionExclusiveBqJMswJ0KWMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/questionnaire-optionExclusive.vue?macro=true";
import { default as questionnaire_45sliderStepValueYb0UBfHoOwMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/questionnaire-sliderStepValue.vue?macro=true";
import { default as questionnaire_45unitOption4UtjEScdUiMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/questionnaire-unitOption.vue?macro=true";
import { default as rendering_45markdownd2jzaScUfTMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/rendering-markdown.vue?macro=true";
import { default as rendering_45xhtml7hEQWyVQ7jMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/rendering-xhtml.vue?macro=true";
import { default as sdc_45questionnaire_45calculatedExpressionHTqNJw8D4KMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/sdc-questionnaire-calculatedExpression.vue?macro=true";
import { default as spineD8a5P1ISSYMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/spine.vue?macro=true";
import { default as system_45metadatas9FwY2svyfMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/system-metadata.vue?macro=true";
import { default as translationcaLU6XgKqaMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/translation.vue?macro=true";
import { default as variablelOJhkQdRi1Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/variable.vue?macro=true";
import { default as all_45typesOq8E1UMcxyMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/all-types.vue?macro=true";
import { default as booleankQbUVv0L44Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/boolean.vue?macro=true";
import { default as choiceGquWxduCY7Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/choice.vue?macro=true";
import { default as dateBO3GcWOYxzMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/date.vue?macro=true";
import { default as dateTimeMCT87LDzB5Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/dateTime.vue?macro=true";
import { default as decimalcIRjJ84hChMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/decimal.vue?macro=true";
import { default as displayAoRnq1p3CxMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/display.vue?macro=true";
import { default as implantf7lZuOdO7SMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/implant.vue?macro=true";
import { default as integergpaX8hFPK8Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/integer.vue?macro=true";
import { default as open_45choicenNjprrf9LgMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/open-choice.vue?macro=true";
import { default as quantityvXSWlzLW4DMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/quantity.vue?macro=true";
import { default as spineVimbBRquaSMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/spine.vue?macro=true";
import { default as stringznlnv9E4TdMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/string.vue?macro=true";
import { default as textv3287lJkOWMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/text.vue?macro=true";
import { default as time1z27YiX2iFMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/time.vue?macro=true";
import { default as maxLengthcZ1r9X8dzzMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.maxLength/maxLength.vue?macro=true";
import { default as boolean2zLUMtwzLJMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/boolean.vue?macro=true";
import { default as choiceFBOM3uzh2SMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/choice.vue?macro=true";
import { default as datefbN78Cvcq2Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/date.vue?macro=true";
import { default as dateTime7ABkJyOXS3Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/dateTime.vue?macro=true";
import { default as decimal9On6xi4g6mMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/decimal.vue?macro=true";
import { default as displayx12R49ToZ2Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/display.vue?macro=true";
import { default as integeroPD9WkXWkaMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/integer.vue?macro=true";
import { default as open_45choiceOgT29LHcSmMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/open-choice.vue?macro=true";
import { default as quantityWZZJTZvbhRMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/quantity.vue?macro=true";
import { default as spineP3O5j5yyqqMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/spine.vue?macro=true";
import { default as stringMYqG87gFICMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/string.vue?macro=true";
import { default as text4aI4dpaNPCMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/text.vue?macro=true";
import { default as time0c1nbcBgZNMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/time.vue?macro=true";
import { default as translationjDZEBZ9fJiMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/translation.vue?macro=true";
import { default as booleanvFEXeFRbviMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/boolean.vue?macro=true";
import { default as choiceZ2cz73EL6qMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/choice.vue?macro=true";
import { default as date7N7ztVmv0sMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/date.vue?macro=true";
import { default as dateTimeaj914ZQ2d2Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/dateTime.vue?macro=true";
import { default as decimalLVDPbxqA46Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/decimal.vue?macro=true";
import { default as implant_45table_46trueVVdK6yoUO5Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/implant-table.true.vue?macro=true";
import { default as integerLeeIF8LHllMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/integer.vue?macro=true";
import { default as open_45choiceEobLsyGE8AMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/open-choice.vue?macro=true";
import { default as quantitydivseUfCLsMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/quantity.vue?macro=true";
import { default as spinettVUiiTrT0Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/spine.vue?macro=true";
import { default as stringFNBZvHfWUZMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/string.vue?macro=true";
import { default as textsnHzRKfkYUMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/text.vue?macro=true";
import { default as time0fvBxtfHjEMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/time.vue?macro=true";
import { default as booleangPLqQHIj8gMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/boolean.vue?macro=true";
import { default as choicezrarut8uCSMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/choice.vue?macro=true";
import { default as dateq1TFO868uoMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/date.vue?macro=true";
import { default as dateTimetdmfxDzS24Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/dateTime.vue?macro=true";
import { default as decimal88LWw0CU42Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/decimal.vue?macro=true";
import { default as displayjEQYjusLP7Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/display.vue?macro=true";
import { default as integerA990pMuCnPMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/integer.vue?macro=true";
import { default as open_45choiceH3eOkEmdPKMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/open-choice.vue?macro=true";
import { default as quantity3KstzXZVc5Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/quantity.vue?macro=true";
import { default as stringpkqU1F6CQmMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/string.vue?macro=true";
import { default as textT53VPCaxYLMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/text.vue?macro=true";
import { default as timevwoEUIRoniMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/time.vue?macro=true";
import { default as summaryPJJbye4iTdMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/layouts/default/summary.vue?macro=true";
import { default as autorenderer_45plugin_45exampleiLm2p5d0D2Meta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/plugins/autorenderer-plugin-example.vue?macro=true";
import { default as mfx_45auto_45rendererZcDSoDBRdAMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer.vue?macro=true";
import { default as vue_45uiQkWTAIpb3VMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/vue-ui.vue?macro=true";
import { default as indexuyUvLKiSgkMeta } from "/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/index.vue?macro=true";
export default [
  {
    name: mfx_45auto_45rendererZcDSoDBRdAMeta?.name ?? "@visiontree-mfx-auto-renderer",
    path: mfx_45auto_45rendererZcDSoDBRdAMeta?.path ?? "/@visiontree/mfx-auto-renderer",
    children: [
  {
    name: consentV1lY64eYOPBgMeta?.name ?? "@visiontree-mfx-auto-renderer-client-consentV1",
    path: consentV1lY64eYOPBgMeta?.path ?? "client/consentV1",
    meta: consentV1lY64eYOPBgMeta || {},
    alias: consentV1lY64eYOPBgMeta?.alias || [],
    redirect: consentV1lY64eYOPBgMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/consentV1.vue").then(m => m.default || m)
  },
  {
    name: data_sharing_consent_v2_465f7SgUVwrCeMeta?.name ?? "@visiontree-mfx-auto-renderer-client-data_sharing_consent_v2.5",
    path: data_sharing_consent_v2_465f7SgUVwrCeMeta?.path ?? "client/data_sharing_consent_v2.5",
    meta: data_sharing_consent_v2_465f7SgUVwrCeMeta || {},
    alias: data_sharing_consent_v2_465f7SgUVwrCeMeta?.alias || [],
    redirect: data_sharing_consent_v2_465f7SgUVwrCeMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/data_sharing_consent_v2.5.vue").then(m => m.default || m)
  },
  {
    name: data_sharing_consent_v2_466aeVxBRUqGXMeta?.name ?? "@visiontree-mfx-auto-renderer-client-data_sharing_consent_v2.6",
    path: data_sharing_consent_v2_466aeVxBRUqGXMeta?.path ?? "client/data_sharing_consent_v2.6",
    meta: data_sharing_consent_v2_466aeVxBRUqGXMeta || {},
    alias: data_sharing_consent_v2_466aeVxBRUqGXMeta?.alias || [],
    redirect: data_sharing_consent_v2_466aeVxBRUqGXMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/data_sharing_consent_v2.6.vue").then(m => m.default || m)
  },
  {
    name: dwg_basic_data_set_v1_460_465EhG3Q8ssbZMeta?.name ?? "@visiontree-mfx-auto-renderer-client-dwg_basic_data_set_v1.0.5",
    path: dwg_basic_data_set_v1_460_465EhG3Q8ssbZMeta?.path ?? "client/dwg_basic_data_set_v1.0.5",
    meta: dwg_basic_data_set_v1_460_465EhG3Q8ssbZMeta || {},
    alias: dwg_basic_data_set_v1_460_465EhG3Q8ssbZMeta?.alias || [],
    redirect: dwg_basic_data_set_v1_460_465EhG3Q8ssbZMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg_basic_data_set_v1.0.5.vue").then(m => m.default || m)
  },
  {
    name: dwg_basic_data_set_v1_460_466Zzzjw8hXZ0Meta?.name ?? "@visiontree-mfx-auto-renderer-client-dwg_basic_data_set_v1.0.6",
    path: dwg_basic_data_set_v1_460_466Zzzjw8hXZ0Meta?.path ?? "client/dwg_basic_data_set_v1.0.6",
    meta: dwg_basic_data_set_v1_460_466Zzzjw8hXZ0Meta || {},
    alias: dwg_basic_data_set_v1_460_466Zzzjw8hXZ0Meta?.alias || [],
    redirect: dwg_basic_data_set_v1_460_466Zzzjw8hXZ0Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg_basic_data_set_v1.0.6.vue").then(m => m.default || m)
  },
  {
    name: dwg_basic_data_set_v3_462_462blSf86YKLGBLMeta?.name ?? "@visiontree-mfx-auto-renderer-client-dwg_basic_data_set_v3.2.2bl",
    path: dwg_basic_data_set_v3_462_462blSf86YKLGBLMeta?.path ?? "client/dwg_basic_data_set_v3.2.2bl",
    meta: dwg_basic_data_set_v3_462_462blSf86YKLGBLMeta || {},
    alias: dwg_basic_data_set_v3_462_462blSf86YKLGBLMeta?.alias || [],
    redirect: dwg_basic_data_set_v3_462_462blSf86YKLGBLMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg_basic_data_set_v3.2.2bl.vue").then(m => m.default || m)
  },
  {
    name: dwg_basic_data_set_v5bOsHG7N5B5Meta?.name ?? "@visiontree-mfx-auto-renderer-client-dwg_basic_data_set_v5",
    path: dwg_basic_data_set_v5bOsHG7N5B5Meta?.path ?? "client/dwg_basic_data_set_v5",
    meta: dwg_basic_data_set_v5bOsHG7N5B5Meta || {},
    alias: dwg_basic_data_set_v5bOsHG7N5B5Meta?.alias || [],
    redirect: dwg_basic_data_set_v5bOsHG7N5B5Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg_basic_data_set_v5.vue").then(m => m.default || m)
  },
  {
    name: dwg_comi_backIbEptuN9QWMeta?.name ?? "@visiontree-mfx-auto-renderer-client-dwg_comi_back",
    path: dwg_comi_backIbEptuN9QWMeta?.path ?? "client/dwg_comi_back",
    meta: dwg_comi_backIbEptuN9QWMeta || {},
    alias: dwg_comi_backIbEptuN9QWMeta?.alias || [],
    redirect: dwg_comi_backIbEptuN9QWMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg_comi_back.vue").then(m => m.default || m)
  },
  {
    name: dwg_comi_neckNwJHX6UHhOMeta?.name ?? "@visiontree-mfx-auto-renderer-client-dwg_comi_neck",
    path: dwg_comi_neckNwJHX6UHhOMeta?.path ?? "client/dwg_comi_neck",
    meta: dwg_comi_neckNwJHX6UHhOMeta || {},
    alias: dwg_comi_neckNwJHX6UHhOMeta?.alias || [],
    redirect: dwg_comi_neckNwJHX6UHhOMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg_comi_neck.vue").then(m => m.default || m)
  },
  {
    name: dwg_eq5dJvcpV81RswMeta?.name ?? "@visiontree-mfx-auto-renderer-client-dwg_eq5d",
    path: dwg_eq5dJvcpV81RswMeta?.path ?? "client/dwg_eq5d",
    meta: dwg_eq5dJvcpV81RswMeta || {},
    alias: dwg_eq5dJvcpV81RswMeta?.alias || [],
    redirect: dwg_eq5dJvcpV81RswMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg_eq5d.vue").then(m => m.default || m)
  },
  {
    name: dwgzKOH9Gl7ALMeta?.name ?? "@visiontree-mfx-auto-renderer-client-dwg",
    path: dwgzKOH9Gl7ALMeta?.path ?? "client/dwg",
    meta: dwgzKOH9Gl7ALMeta || {},
    alias: dwgzKOH9Gl7ALMeta?.alias || [],
    redirect: dwgzKOH9Gl7ALMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/dwg.vue").then(m => m.default || m)
  },
  {
    name: eprd1tWqYIDh2rMeta?.name ?? "@visiontree-mfx-auto-renderer-client-eprd",
    path: eprd1tWqYIDh2rMeta?.path ?? "client/eprd",
    meta: eprd1tWqYIDh2rMeta || {},
    alias: eprd1tWqYIDh2rMeta?.alias || [],
    redirect: eprd1tWqYIDh2rMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/eprd.vue").then(m => m.default || m)
  },
  {
    name: basic_45datatn0USC7S9AMeta?.name ?? "@visiontree-mfx-auto-renderer-client-mint-basic-data",
    path: basic_45datatn0USC7S9AMeta?.path ?? "client/mint/basic-data",
    meta: basic_45datatn0USC7S9AMeta || {},
    alias: basic_45datatn0USC7S9AMeta?.alias || [],
    redirect: basic_45datatn0USC7S9AMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/mint/basic-data.vue").then(m => m.default || m)
  },
  {
    name: consent_45plain_45mintHI59IQgp6EMeta?.name ?? "@visiontree-mfx-auto-renderer-client-mint-consent-plain-mint",
    path: consent_45plain_45mintHI59IQgp6EMeta?.path ?? "client/mint/consent-plain-mint",
    meta: consent_45plain_45mintHI59IQgp6EMeta || {},
    alias: consent_45plain_45mintHI59IQgp6EMeta?.alias || [],
    redirect: consent_45plain_45mintHI59IQgp6EMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/mint/consent-plain-mint.vue").then(m => m.default || m)
  },
  {
    name: template_dwg_ndiv6oW9gOPIqMeta?.name ?? "@visiontree-mfx-auto-renderer-client-template_dwg_ndi",
    path: template_dwg_ndiv6oW9gOPIqMeta?.path ?? "client/template_dwg_ndi",
    meta: template_dwg_ndiv6oW9gOPIqMeta || {},
    alias: template_dwg_ndiv6oW9gOPIqMeta?.alias || [],
    redirect: template_dwg_ndiv6oW9gOPIqMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/template_dwg_ndi.vue").then(m => m.default || m)
  },
  {
    name: template_dwg_odi4Q3pjXe9MXMeta?.name ?? "@visiontree-mfx-auto-renderer-client-template_dwg_odi",
    path: template_dwg_odi4Q3pjXe9MXMeta?.path ?? "client/template_dwg_odi",
    meta: template_dwg_odi4Q3pjXe9MXMeta || {},
    alias: template_dwg_odi4Q3pjXe9MXMeta?.alias || [],
    redirect: template_dwg_odi4Q3pjXe9MXMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/client/template_dwg_odi.vue").then(m => m.default || m)
  },
  {
    name: consent1ybBrrAbOvMeta?.name ?? "@visiontree-mfx-auto-renderer-features-consent",
    path: consent1ybBrrAbOvMeta?.path ?? "features/consent",
    meta: consent1ybBrrAbOvMeta || {},
    alias: consent1ybBrrAbOvMeta?.alias || [],
    redirect: consent1ybBrrAbOvMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/consent.vue").then(m => m.default || m)
  },
  {
    name: custom_45color_45themeMmI8AxQ7mmMeta?.name ?? "@visiontree-mfx-auto-renderer-features-custom-color-theme",
    path: custom_45color_45themeMmI8AxQ7mmMeta?.path ?? "features/custom-color-theme",
    meta: custom_45color_45themeMmI8AxQ7mmMeta || {},
    alias: custom_45color_45themeMmI8AxQ7mmMeta?.alias || [],
    redirect: custom_45color_45themeMmI8AxQ7mmMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/custom-color-theme.vue").then(m => m.default || m)
  },
  {
    name: custom_45componentsj3xaeHb185Meta?.name ?? "@visiontree-mfx-auto-renderer-features-custom-components",
    path: custom_45componentsj3xaeHb185Meta?.path ?? "features/custom-components",
    meta: custom_45componentsj3xaeHb185Meta || {},
    alias: custom_45componentsj3xaeHb185Meta?.alias || [],
    redirect: custom_45componentsj3xaeHb185Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/custom-components.vue").then(m => m.default || m)
  },
  {
    name: emphasize_45optional_45itemsRtMqvwyIgWMeta?.name ?? "@visiontree-mfx-auto-renderer-features-emphasize-optional-items",
    path: emphasize_45optional_45itemsRtMqvwyIgWMeta?.path ?? "features/emphasize-optional-items",
    meta: emphasize_45optional_45itemsRtMqvwyIgWMeta || {},
    alias: emphasize_45optional_45itemsRtMqvwyIgWMeta?.alias || [],
    redirect: emphasize_45optional_45itemsRtMqvwyIgWMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/emphasize-optional-items.vue").then(m => m.default || m)
  },
  {
    name: paginatedremsteDDqAMeta?.name ?? "@visiontree-mfx-auto-renderer-features-paginated",
    path: paginatedremsteDDqAMeta?.path ?? "features/paginated",
    meta: paginatedremsteDDqAMeta || {},
    alias: paginatedremsteDDqAMeta?.alias || [],
    redirect: paginatedremsteDDqAMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/paginated.vue").then(m => m.default || m)
  },
  {
    name: scroll_45to_45invalidmWLtYHN0qeMeta?.name ?? "@visiontree-mfx-auto-renderer-features-scroll-to-invalid",
    path: scroll_45to_45invalidmWLtYHN0qeMeta?.path ?? "features/scroll-to-invalid",
    meta: scroll_45to_45invalidmWLtYHN0qeMeta || {},
    alias: scroll_45to_45invalidmWLtYHN0qeMeta?.alias || [],
    redirect: scroll_45to_45invalidmWLtYHN0qeMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/scroll-to-invalid.vue").then(m => m.default || m)
  },
  {
    name: stress_45testzRGGTee3TyMeta?.name ?? "@visiontree-mfx-auto-renderer-features-stress-test",
    path: stress_45testzRGGTee3TyMeta?.path ?? "features/stress-test",
    meta: stress_45testzRGGTee3TyMeta || {},
    alias: stress_45testzRGGTee3TyMeta?.alias || [],
    redirect: stress_45testzRGGTee3TyMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/stress-test.vue").then(m => m.default || m)
  },
  {
    name: themingeoBUZ1uP3WMeta?.name ?? "@visiontree-mfx-auto-renderer-features-theming",
    path: themingeoBUZ1uP3WMeta?.path ?? "features/theming",
    meta: themingeoBUZ1uP3WMeta || {},
    alias: themingeoBUZ1uP3WMeta?.alias || [],
    redirect: themingeoBUZ1uP3WMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/features/theming.vue").then(m => m.default || m)
  },
  {
    name: attachment4P2pCD9vCUMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-attachment",
    path: attachment4P2pCD9vCUMeta?.path ?? "fhir/Questionnaire.item.enableWhen/attachment",
    meta: attachment4P2pCD9vCUMeta || {},
    alias: attachment4P2pCD9vCUMeta?.alias || [],
    redirect: attachment4P2pCD9vCUMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/attachment.vue").then(m => m.default || m)
  },
  {
    name: booleanrOq71hBKUYMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-boolean",
    path: booleanrOq71hBKUYMeta?.path ?? "fhir/Questionnaire.item.enableWhen/boolean",
    meta: booleanrOq71hBKUYMeta || {},
    alias: booleanrOq71hBKUYMeta?.alias || [],
    redirect: booleanrOq71hBKUYMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/boolean.vue").then(m => m.default || m)
  },
  {
    name: choice44gXjcmxnWMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-choice",
    path: choice44gXjcmxnWMeta?.path ?? "fhir/Questionnaire.item.enableWhen/choice",
    meta: choice44gXjcmxnWMeta || {},
    alias: choice44gXjcmxnWMeta?.alias || [],
    redirect: choice44gXjcmxnWMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/choice.vue").then(m => m.default || m)
  },
  {
    name: date_45timeu967s2OSDuMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-date-time",
    path: date_45timeu967s2OSDuMeta?.path ?? "fhir/Questionnaire.item.enableWhen/date-time",
    meta: date_45timeu967s2OSDuMeta || {},
    alias: date_45timeu967s2OSDuMeta?.alias || [],
    redirect: date_45timeu967s2OSDuMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/date-time.vue").then(m => m.default || m)
  },
  {
    name: dateBGhwBwXQDiMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-date",
    path: dateBGhwBwXQDiMeta?.path ?? "fhir/Questionnaire.item.enableWhen/date",
    meta: dateBGhwBwXQDiMeta || {},
    alias: dateBGhwBwXQDiMeta?.alias || [],
    redirect: dateBGhwBwXQDiMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/date.vue").then(m => m.default || m)
  },
  {
    name: decimalDyHvlm5aOyMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-decimal",
    path: decimalDyHvlm5aOyMeta?.path ?? "fhir/Questionnaire.item.enableWhen/decimal",
    meta: decimalDyHvlm5aOyMeta || {},
    alias: decimalDyHvlm5aOyMeta?.alias || [],
    redirect: decimalDyHvlm5aOyMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/decimal.vue").then(m => m.default || m)
  },
  {
    name: displayTrdSc3LMOcMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-display",
    path: displayTrdSc3LMOcMeta?.path ?? "fhir/Questionnaire.item.enableWhen/display",
    meta: displayTrdSc3LMOcMeta || {},
    alias: displayTrdSc3LMOcMeta?.alias || [],
    redirect: displayTrdSc3LMOcMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/display.vue").then(m => m.default || m)
  },
  {
    name: grouparhhRS3lrOMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-group",
    path: grouparhhRS3lrOMeta?.path ?? "fhir/Questionnaire.item.enableWhen/group",
    meta: grouparhhRS3lrOMeta || {},
    alias: grouparhhRS3lrOMeta?.alias || [],
    redirect: grouparhhRS3lrOMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/group.vue").then(m => m.default || m)
  },
  {
    name: implant_45tableFJdT2Qi106Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-implant-table",
    path: implant_45tableFJdT2Qi106Meta?.path ?? "fhir/Questionnaire.item.enableWhen/implant-table",
    meta: implant_45tableFJdT2Qi106Meta || {},
    alias: implant_45tableFJdT2Qi106Meta?.alias || [],
    redirect: implant_45tableFJdT2Qi106Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/implant-table.vue").then(m => m.default || m)
  },
  {
    name: integerCUeC19wrHFMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-integer",
    path: integerCUeC19wrHFMeta?.path ?? "fhir/Questionnaire.item.enableWhen/integer",
    meta: integerCUeC19wrHFMeta || {},
    alias: integerCUeC19wrHFMeta?.alias || [],
    redirect: integerCUeC19wrHFMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/integer.vue").then(m => m.default || m)
  },
  {
    name: open_45choicent3fLxIuDuMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-open-choice",
    path: open_45choicent3fLxIuDuMeta?.path ?? "fhir/Questionnaire.item.enableWhen/open-choice",
    meta: open_45choicent3fLxIuDuMeta || {},
    alias: open_45choicent3fLxIuDuMeta?.alias || [],
    redirect: open_45choicent3fLxIuDuMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/open-choice.vue").then(m => m.default || m)
  },
  {
    name: quantitykqN1BPmGj6Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-quantity",
    path: quantitykqN1BPmGj6Meta?.path ?? "fhir/Questionnaire.item.enableWhen/quantity",
    meta: quantitykqN1BPmGj6Meta || {},
    alias: quantitykqN1BPmGj6Meta?.alias || [],
    redirect: quantitykqN1BPmGj6Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/quantity.vue").then(m => m.default || m)
  },
  {
    name: referencegfM9GZ1bE9Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-reference",
    path: referencegfM9GZ1bE9Meta?.path ?? "fhir/Questionnaire.item.enableWhen/reference",
    meta: referencegfM9GZ1bE9Meta || {},
    alias: referencegfM9GZ1bE9Meta?.alias || [],
    redirect: referencegfM9GZ1bE9Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/reference.vue").then(m => m.default || m)
  },
  {
    name: spineXOBzV2DT0fMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-spine",
    path: spineXOBzV2DT0fMeta?.path ?? "fhir/Questionnaire.item.enableWhen/spine",
    meta: spineXOBzV2DT0fMeta || {},
    alias: spineXOBzV2DT0fMeta?.alias || [],
    redirect: spineXOBzV2DT0fMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/spine.vue").then(m => m.default || m)
  },
  {
    name: stringoQZSeo3WMHMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-string",
    path: stringoQZSeo3WMHMeta?.path ?? "fhir/Questionnaire.item.enableWhen/string",
    meta: stringoQZSeo3WMHMeta || {},
    alias: stringoQZSeo3WMHMeta?.alias || [],
    redirect: stringoQZSeo3WMHMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/string.vue").then(m => m.default || m)
  },
  {
    name: summarydNrpH7Be5cMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-summary",
    path: summarydNrpH7Be5cMeta?.path ?? "fhir/Questionnaire.item.enableWhen/summary",
    meta: summarydNrpH7Be5cMeta || {},
    alias: summarydNrpH7Be5cMeta?.alias || [],
    redirect: summarydNrpH7Be5cMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/summary.vue").then(m => m.default || m)
  },
  {
    name: text3iH6RsAIeeMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-text",
    path: text3iH6RsAIeeMeta?.path ?? "fhir/Questionnaire.item.enableWhen/text",
    meta: text3iH6RsAIeeMeta || {},
    alias: text3iH6RsAIeeMeta?.alias || [],
    redirect: text3iH6RsAIeeMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/text.vue").then(m => m.default || m)
  },
  {
    name: timeIXiRpYOeVvMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-time",
    path: timeIXiRpYOeVvMeta?.path ?? "fhir/Questionnaire.item.enableWhen/time",
    meta: timeIXiRpYOeVvMeta || {},
    alias: timeIXiRpYOeVvMeta?.alias || [],
    redirect: timeIXiRpYOeVvMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/time.vue").then(m => m.default || m)
  },
  {
    name: urlJ2z8uaLUPhMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.enableWhen-url",
    path: urlJ2z8uaLUPhMeta?.path ?? "fhir/Questionnaire.item.enableWhen/url",
    meta: urlJ2z8uaLUPhMeta || {},
    alias: urlJ2z8uaLUPhMeta?.alias || [],
    redirect: urlJ2z8uaLUPhMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.enableWhen/url.vue").then(m => m.default || m)
  },
  {
    name: cqf_45expressionS0Obap5fzZMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-cqf-expression",
    path: cqf_45expressionS0Obap5fzZMeta?.path ?? "fhir/Questionnaire.item.extension/cqf-expression",
    meta: cqf_45expressionS0Obap5fzZMeta || {},
    alias: cqf_45expressionS0Obap5fzZMeta?.alias || [],
    redirect: cqf_45expressionS0Obap5fzZMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/cqf-expression.vue").then(m => m.default || m)
  },
  {
    name: durationonvWD9TtyBMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-duration",
    path: durationonvWD9TtyBMeta?.path ?? "fhir/Questionnaire.item.extension/duration",
    meta: durationonvWD9TtyBMeta || {},
    alias: durationonvWD9TtyBMeta?.alias || [],
    redirect: durationonvWD9TtyBMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/duration.vue").then(m => m.default || m)
  },
  {
    name: enable_45when_45expressionXXchaEeVgcMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-enable-when-expression",
    path: enable_45when_45expressionXXchaEeVgcMeta?.path ?? "fhir/Questionnaire.item.extension/enable-when-expression",
    meta: enable_45when_45expressionXXchaEeVgcMeta || {},
    alias: enable_45when_45expressionXXchaEeVgcMeta?.alias || [],
    redirect: enable_45when_45expressionXXchaEeVgcMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/enable-when-expression.vue").then(m => m.default || m)
  },
  {
    name: entry_45formatK5bhnFn3k6Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-entry-format",
    path: entry_45formatK5bhnFn3k6Meta?.path ?? "fhir/Questionnaire.item.extension/entry-format",
    meta: entry_45formatK5bhnFn3k6Meta || {},
    alias: entry_45formatK5bhnFn3k6Meta?.alias || [],
    redirect: entry_45formatK5bhnFn3k6Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/entry-format.vue").then(m => m.default || m)
  },
  {
    name: hiddenT8S7VvHov5Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-hidden",
    path: hiddenT8S7VvHov5Meta?.path ?? "fhir/Questionnaire.item.extension/hidden",
    meta: hiddenT8S7VvHov5Meta || {},
    alias: hiddenT8S7VvHov5Meta?.alias || [],
    redirect: hiddenT8S7VvHov5Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/hidden.vue").then(m => m.default || m)
  },
  {
    name: implant_45no_45repeats2dSctver77Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-implant-no-repeats",
    path: implant_45no_45repeats2dSctver77Meta?.path ?? "fhir/Questionnaire.item.extension/implant-no-repeats",
    meta: implant_45no_45repeats2dSctver77Meta || {},
    alias: implant_45no_45repeats2dSctver77Meta?.alias || [],
    redirect: implant_45no_45repeats2dSctver77Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/implant-no-repeats.vue").then(m => m.default || m)
  },
  {
    name: implant_45with_45response1noFYn3pU3Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-implant-with-response",
    path: implant_45with_45response1noFYn3pU3Meta?.path ?? "fhir/Questionnaire.item.extension/implant-with-response",
    meta: implant_45with_45response1noFYn3pU3Meta || {},
    alias: implant_45with_45response1noFYn3pU3Meta?.alias || [],
    redirect: implant_45with_45response1noFYn3pU3Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/implant-with-response.vue").then(m => m.default || m)
  },
  {
    name: implant5KX8yK1hD4Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-implant",
    path: implant5KX8yK1hD4Meta?.path ?? "fhir/Questionnaire.item.extension/implant",
    meta: implant5KX8yK1hD4Meta || {},
    alias: implant5KX8yK1hD4Meta?.alias || [],
    redirect: implant5KX8yK1hD4Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/implant.vue").then(m => m.default || m)
  },
  {
    name: column_45positionUDrXTaAMeDMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-layout-column-position",
    path: column_45positionUDrXTaAMeDMeta?.path ?? "fhir/Questionnaire.item.extension/layout/column-position",
    meta: column_45positionUDrXTaAMeDMeta || {},
    alias: column_45positionUDrXTaAMeDMeta?.alias || [],
    redirect: column_45positionUDrXTaAMeDMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/layout/column-position.vue").then(m => m.default || m)
  },
  {
    name: maxValue1ZSwiwI0ccMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-maxValue",
    path: maxValue1ZSwiwI0ccMeta?.path ?? "fhir/Questionnaire.item.extension/maxValue",
    meta: maxValue1ZSwiwI0ccMeta || {},
    alias: maxValue1ZSwiwI0ccMeta?.alias || [],
    redirect: maxValue1ZSwiwI0ccMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/maxValue.vue").then(m => m.default || m)
  },
  {
    name: minValueR2d4rnKQA1Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-minValue",
    path: minValueR2d4rnKQA1Meta?.path ?? "fhir/Questionnaire.item.extension/minValue",
    meta: minValueR2d4rnKQA1Meta || {},
    alias: minValueR2d4rnKQA1Meta?.alias || [],
    redirect: minValueR2d4rnKQA1Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/minValue.vue").then(m => m.default || m)
  },
  {
    name: questionnaire_45constraintHVNxF699dDMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-questionnaire-constraint",
    path: questionnaire_45constraintHVNxF699dDMeta?.path ?? "fhir/Questionnaire.item.extension/questionnaire-constraint",
    meta: questionnaire_45constraintHVNxF699dDMeta || {},
    alias: questionnaire_45constraintHVNxF699dDMeta?.alias || [],
    redirect: questionnaire_45constraintHVNxF699dDMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/questionnaire-constraint.vue").then(m => m.default || m)
  },
  {
    name: Help_45Buttonpb8BMDlYQYMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-questionnaire-itemControl-Help-Button",
    path: Help_45Buttonpb8BMDlYQYMeta?.path ?? "fhir/Questionnaire.item.extension/questionnaire-itemControl/Help-Button",
    meta: Help_45Buttonpb8BMDlYQYMeta || {},
    alias: Help_45Buttonpb8BMDlYQYMeta?.alias || [],
    redirect: Help_45Buttonpb8BMDlYQYMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/questionnaire-itemControl/Help-Button.vue").then(m => m.default || m)
  },
  {
    name: Partial_45DateAYhKogg8uMMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-questionnaire-itemControl-Partial-Date",
    path: Partial_45DateAYhKogg8uMMeta?.path ?? "fhir/Questionnaire.item.extension/questionnaire-itemControl/Partial-Date",
    meta: Partial_45DateAYhKogg8uMMeta || {},
    alias: Partial_45DateAYhKogg8uMMeta?.alias || [],
    redirect: Partial_45DateAYhKogg8uMMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/questionnaire-itemControl/Partial-Date.vue").then(m => m.default || m)
  },
  {
    name: SlideraqAmpUsrY8Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-questionnaire-itemControl-Slider",
    path: SlideraqAmpUsrY8Meta?.path ?? "fhir/Questionnaire.item.extension/questionnaire-itemControl/Slider",
    meta: SlideraqAmpUsrY8Meta || {},
    alias: SlideraqAmpUsrY8Meta?.alias || [],
    redirect: SlideraqAmpUsrY8Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/questionnaire-itemControl/Slider.vue").then(m => m.default || m)
  },
  {
    name: questionnaire_45optionExclusiveBqJMswJ0KWMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-questionnaire-optionExclusive",
    path: questionnaire_45optionExclusiveBqJMswJ0KWMeta?.path ?? "fhir/Questionnaire.item.extension/questionnaire-optionExclusive",
    meta: questionnaire_45optionExclusiveBqJMswJ0KWMeta || {},
    alias: questionnaire_45optionExclusiveBqJMswJ0KWMeta?.alias || [],
    redirect: questionnaire_45optionExclusiveBqJMswJ0KWMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/questionnaire-optionExclusive.vue").then(m => m.default || m)
  },
  {
    name: questionnaire_45sliderStepValueYb0UBfHoOwMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-questionnaire-sliderStepValue",
    path: questionnaire_45sliderStepValueYb0UBfHoOwMeta?.path ?? "fhir/Questionnaire.item.extension/questionnaire-sliderStepValue",
    meta: questionnaire_45sliderStepValueYb0UBfHoOwMeta || {},
    alias: questionnaire_45sliderStepValueYb0UBfHoOwMeta?.alias || [],
    redirect: questionnaire_45sliderStepValueYb0UBfHoOwMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/questionnaire-sliderStepValue.vue").then(m => m.default || m)
  },
  {
    name: questionnaire_45unitOption4UtjEScdUiMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-questionnaire-unitOption",
    path: questionnaire_45unitOption4UtjEScdUiMeta?.path ?? "fhir/Questionnaire.item.extension/questionnaire-unitOption",
    meta: questionnaire_45unitOption4UtjEScdUiMeta || {},
    alias: questionnaire_45unitOption4UtjEScdUiMeta?.alias || [],
    redirect: questionnaire_45unitOption4UtjEScdUiMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/questionnaire-unitOption.vue").then(m => m.default || m)
  },
  {
    name: rendering_45markdownd2jzaScUfTMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-rendering-markdown",
    path: rendering_45markdownd2jzaScUfTMeta?.path ?? "fhir/Questionnaire.item.extension/rendering-markdown",
    meta: rendering_45markdownd2jzaScUfTMeta || {},
    alias: rendering_45markdownd2jzaScUfTMeta?.alias || [],
    redirect: rendering_45markdownd2jzaScUfTMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/rendering-markdown.vue").then(m => m.default || m)
  },
  {
    name: rendering_45xhtml7hEQWyVQ7jMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-rendering-xhtml",
    path: rendering_45xhtml7hEQWyVQ7jMeta?.path ?? "fhir/Questionnaire.item.extension/rendering-xhtml",
    meta: rendering_45xhtml7hEQWyVQ7jMeta || {},
    alias: rendering_45xhtml7hEQWyVQ7jMeta?.alias || [],
    redirect: rendering_45xhtml7hEQWyVQ7jMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/rendering-xhtml.vue").then(m => m.default || m)
  },
  {
    name: sdc_45questionnaire_45calculatedExpressionHTqNJw8D4KMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-sdc-questionnaire-calculatedExpression",
    path: sdc_45questionnaire_45calculatedExpressionHTqNJw8D4KMeta?.path ?? "fhir/Questionnaire.item.extension/sdc-questionnaire-calculatedExpression",
    meta: sdc_45questionnaire_45calculatedExpressionHTqNJw8D4KMeta || {},
    alias: sdc_45questionnaire_45calculatedExpressionHTqNJw8D4KMeta?.alias || [],
    redirect: sdc_45questionnaire_45calculatedExpressionHTqNJw8D4KMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/sdc-questionnaire-calculatedExpression.vue").then(m => m.default || m)
  },
  {
    name: spineD8a5P1ISSYMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-spine",
    path: spineD8a5P1ISSYMeta?.path ?? "fhir/Questionnaire.item.extension/spine",
    meta: spineD8a5P1ISSYMeta || {},
    alias: spineD8a5P1ISSYMeta?.alias || [],
    redirect: spineD8a5P1ISSYMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/spine.vue").then(m => m.default || m)
  },
  {
    name: system_45metadatas9FwY2svyfMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-system-metadata",
    path: system_45metadatas9FwY2svyfMeta?.path ?? "fhir/Questionnaire.item.extension/system-metadata",
    meta: system_45metadatas9FwY2svyfMeta || {},
    alias: system_45metadatas9FwY2svyfMeta?.alias || [],
    redirect: system_45metadatas9FwY2svyfMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/system-metadata.vue").then(m => m.default || m)
  },
  {
    name: translationcaLU6XgKqaMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-translation",
    path: translationcaLU6XgKqaMeta?.path ?? "fhir/Questionnaire.item.extension/translation",
    meta: translationcaLU6XgKqaMeta || {},
    alias: translationcaLU6XgKqaMeta?.alias || [],
    redirect: translationcaLU6XgKqaMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/translation.vue").then(m => m.default || m)
  },
  {
    name: variablelOJhkQdRi1Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.extension-variable",
    path: variablelOJhkQdRi1Meta?.path ?? "fhir/Questionnaire.item.extension/variable",
    meta: variablelOJhkQdRi1Meta || {},
    alias: variablelOJhkQdRi1Meta?.alias || [],
    redirect: variablelOJhkQdRi1Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.extension/variable.vue").then(m => m.default || m)
  },
  {
    name: all_45typesOq8E1UMcxyMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-all-types",
    path: all_45typesOq8E1UMcxyMeta?.path ?? "fhir/Questionnaire.item.initial/all-types",
    meta: all_45typesOq8E1UMcxyMeta || {},
    alias: all_45typesOq8E1UMcxyMeta?.alias || [],
    redirect: all_45typesOq8E1UMcxyMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/all-types.vue").then(m => m.default || m)
  },
  {
    name: booleankQbUVv0L44Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-boolean",
    path: booleankQbUVv0L44Meta?.path ?? "fhir/Questionnaire.item.initial/boolean",
    meta: booleankQbUVv0L44Meta || {},
    alias: booleankQbUVv0L44Meta?.alias || [],
    redirect: booleankQbUVv0L44Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/boolean.vue").then(m => m.default || m)
  },
  {
    name: choiceGquWxduCY7Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-choice",
    path: choiceGquWxduCY7Meta?.path ?? "fhir/Questionnaire.item.initial/choice",
    meta: choiceGquWxduCY7Meta || {},
    alias: choiceGquWxduCY7Meta?.alias || [],
    redirect: choiceGquWxduCY7Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/choice.vue").then(m => m.default || m)
  },
  {
    name: dateBO3GcWOYxzMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-date",
    path: dateBO3GcWOYxzMeta?.path ?? "fhir/Questionnaire.item.initial/date",
    meta: dateBO3GcWOYxzMeta || {},
    alias: dateBO3GcWOYxzMeta?.alias || [],
    redirect: dateBO3GcWOYxzMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/date.vue").then(m => m.default || m)
  },
  {
    name: dateTimeMCT87LDzB5Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-dateTime",
    path: dateTimeMCT87LDzB5Meta?.path ?? "fhir/Questionnaire.item.initial/dateTime",
    meta: dateTimeMCT87LDzB5Meta || {},
    alias: dateTimeMCT87LDzB5Meta?.alias || [],
    redirect: dateTimeMCT87LDzB5Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/dateTime.vue").then(m => m.default || m)
  },
  {
    name: decimalcIRjJ84hChMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-decimal",
    path: decimalcIRjJ84hChMeta?.path ?? "fhir/Questionnaire.item.initial/decimal",
    meta: decimalcIRjJ84hChMeta || {},
    alias: decimalcIRjJ84hChMeta?.alias || [],
    redirect: decimalcIRjJ84hChMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/decimal.vue").then(m => m.default || m)
  },
  {
    name: displayAoRnq1p3CxMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-display",
    path: displayAoRnq1p3CxMeta?.path ?? "fhir/Questionnaire.item.initial/display",
    meta: displayAoRnq1p3CxMeta || {},
    alias: displayAoRnq1p3CxMeta?.alias || [],
    redirect: displayAoRnq1p3CxMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/display.vue").then(m => m.default || m)
  },
  {
    name: implantf7lZuOdO7SMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-implant",
    path: implantf7lZuOdO7SMeta?.path ?? "fhir/Questionnaire.item.initial/implant",
    meta: implantf7lZuOdO7SMeta || {},
    alias: implantf7lZuOdO7SMeta?.alias || [],
    redirect: implantf7lZuOdO7SMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/implant.vue").then(m => m.default || m)
  },
  {
    name: integergpaX8hFPK8Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-integer",
    path: integergpaX8hFPK8Meta?.path ?? "fhir/Questionnaire.item.initial/integer",
    meta: integergpaX8hFPK8Meta || {},
    alias: integergpaX8hFPK8Meta?.alias || [],
    redirect: integergpaX8hFPK8Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/integer.vue").then(m => m.default || m)
  },
  {
    name: open_45choicenNjprrf9LgMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-open-choice",
    path: open_45choicenNjprrf9LgMeta?.path ?? "fhir/Questionnaire.item.initial/open-choice",
    meta: open_45choicenNjprrf9LgMeta || {},
    alias: open_45choicenNjprrf9LgMeta?.alias || [],
    redirect: open_45choicenNjprrf9LgMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/open-choice.vue").then(m => m.default || m)
  },
  {
    name: quantityvXSWlzLW4DMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-quantity",
    path: quantityvXSWlzLW4DMeta?.path ?? "fhir/Questionnaire.item.initial/quantity",
    meta: quantityvXSWlzLW4DMeta || {},
    alias: quantityvXSWlzLW4DMeta?.alias || [],
    redirect: quantityvXSWlzLW4DMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/quantity.vue").then(m => m.default || m)
  },
  {
    name: spineVimbBRquaSMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-spine",
    path: spineVimbBRquaSMeta?.path ?? "fhir/Questionnaire.item.initial/spine",
    meta: spineVimbBRquaSMeta || {},
    alias: spineVimbBRquaSMeta?.alias || [],
    redirect: spineVimbBRquaSMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/spine.vue").then(m => m.default || m)
  },
  {
    name: stringznlnv9E4TdMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-string",
    path: stringznlnv9E4TdMeta?.path ?? "fhir/Questionnaire.item.initial/string",
    meta: stringznlnv9E4TdMeta || {},
    alias: stringznlnv9E4TdMeta?.alias || [],
    redirect: stringznlnv9E4TdMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/string.vue").then(m => m.default || m)
  },
  {
    name: textv3287lJkOWMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-text",
    path: textv3287lJkOWMeta?.path ?? "fhir/Questionnaire.item.initial/text",
    meta: textv3287lJkOWMeta || {},
    alias: textv3287lJkOWMeta?.alias || [],
    redirect: textv3287lJkOWMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/text.vue").then(m => m.default || m)
  },
  {
    name: time1z27YiX2iFMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.initial-time",
    path: time1z27YiX2iFMeta?.path ?? "fhir/Questionnaire.item.initial/time",
    meta: time1z27YiX2iFMeta || {},
    alias: time1z27YiX2iFMeta?.alias || [],
    redirect: time1z27YiX2iFMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.initial/time.vue").then(m => m.default || m)
  },
  {
    name: maxLengthcZ1r9X8dzzMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.maxLength-maxLength",
    path: maxLengthcZ1r9X8dzzMeta?.path ?? "fhir/Questionnaire.item.maxLength/maxLength",
    meta: maxLengthcZ1r9X8dzzMeta || {},
    alias: maxLengthcZ1r9X8dzzMeta?.alias || [],
    redirect: maxLengthcZ1r9X8dzzMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.maxLength/maxLength.vue").then(m => m.default || m)
  },
  {
    name: boolean2zLUMtwzLJMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.repeats-boolean",
    path: boolean2zLUMtwzLJMeta?.path ?? "fhir/Questionnaire.item.repeats/boolean",
    meta: boolean2zLUMtwzLJMeta || {},
    alias: boolean2zLUMtwzLJMeta?.alias || [],
    redirect: boolean2zLUMtwzLJMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/boolean.vue").then(m => m.default || m)
  },
  {
    name: choiceFBOM3uzh2SMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.repeats-choice",
    path: choiceFBOM3uzh2SMeta?.path ?? "fhir/Questionnaire.item.repeats/choice",
    meta: choiceFBOM3uzh2SMeta || {},
    alias: choiceFBOM3uzh2SMeta?.alias || [],
    redirect: choiceFBOM3uzh2SMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/choice.vue").then(m => m.default || m)
  },
  {
    name: datefbN78Cvcq2Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.repeats-date",
    path: datefbN78Cvcq2Meta?.path ?? "fhir/Questionnaire.item.repeats/date",
    meta: datefbN78Cvcq2Meta || {},
    alias: datefbN78Cvcq2Meta?.alias || [],
    redirect: datefbN78Cvcq2Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/date.vue").then(m => m.default || m)
  },
  {
    name: dateTime7ABkJyOXS3Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.repeats-dateTime",
    path: dateTime7ABkJyOXS3Meta?.path ?? "fhir/Questionnaire.item.repeats/dateTime",
    meta: dateTime7ABkJyOXS3Meta || {},
    alias: dateTime7ABkJyOXS3Meta?.alias || [],
    redirect: dateTime7ABkJyOXS3Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/dateTime.vue").then(m => m.default || m)
  },
  {
    name: decimal9On6xi4g6mMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.repeats-decimal",
    path: decimal9On6xi4g6mMeta?.path ?? "fhir/Questionnaire.item.repeats/decimal",
    meta: decimal9On6xi4g6mMeta || {},
    alias: decimal9On6xi4g6mMeta?.alias || [],
    redirect: decimal9On6xi4g6mMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/decimal.vue").then(m => m.default || m)
  },
  {
    name: displayx12R49ToZ2Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.repeats-display",
    path: displayx12R49ToZ2Meta?.path ?? "fhir/Questionnaire.item.repeats/display",
    meta: displayx12R49ToZ2Meta || {},
    alias: displayx12R49ToZ2Meta?.alias || [],
    redirect: displayx12R49ToZ2Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/display.vue").then(m => m.default || m)
  },
  {
    name: integeroPD9WkXWkaMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.repeats-integer",
    path: integeroPD9WkXWkaMeta?.path ?? "fhir/Questionnaire.item.repeats/integer",
    meta: integeroPD9WkXWkaMeta || {},
    alias: integeroPD9WkXWkaMeta?.alias || [],
    redirect: integeroPD9WkXWkaMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/integer.vue").then(m => m.default || m)
  },
  {
    name: open_45choiceOgT29LHcSmMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.repeats-open-choice",
    path: open_45choiceOgT29LHcSmMeta?.path ?? "fhir/Questionnaire.item.repeats/open-choice",
    meta: open_45choiceOgT29LHcSmMeta || {},
    alias: open_45choiceOgT29LHcSmMeta?.alias || [],
    redirect: open_45choiceOgT29LHcSmMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/open-choice.vue").then(m => m.default || m)
  },
  {
    name: quantityWZZJTZvbhRMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.repeats-quantity",
    path: quantityWZZJTZvbhRMeta?.path ?? "fhir/Questionnaire.item.repeats/quantity",
    meta: quantityWZZJTZvbhRMeta || {},
    alias: quantityWZZJTZvbhRMeta?.alias || [],
    redirect: quantityWZZJTZvbhRMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/quantity.vue").then(m => m.default || m)
  },
  {
    name: spineP3O5j5yyqqMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.repeats-spine",
    path: spineP3O5j5yyqqMeta?.path ?? "fhir/Questionnaire.item.repeats/spine",
    meta: spineP3O5j5yyqqMeta || {},
    alias: spineP3O5j5yyqqMeta?.alias || [],
    redirect: spineP3O5j5yyqqMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/spine.vue").then(m => m.default || m)
  },
  {
    name: stringMYqG87gFICMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.repeats-string",
    path: stringMYqG87gFICMeta?.path ?? "fhir/Questionnaire.item.repeats/string",
    meta: stringMYqG87gFICMeta || {},
    alias: stringMYqG87gFICMeta?.alias || [],
    redirect: stringMYqG87gFICMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/string.vue").then(m => m.default || m)
  },
  {
    name: text4aI4dpaNPCMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.repeats-text",
    path: text4aI4dpaNPCMeta?.path ?? "fhir/Questionnaire.item.repeats/text",
    meta: text4aI4dpaNPCMeta || {},
    alias: text4aI4dpaNPCMeta?.alias || [],
    redirect: text4aI4dpaNPCMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/text.vue").then(m => m.default || m)
  },
  {
    name: time0c1nbcBgZNMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.repeats-time",
    path: time0c1nbcBgZNMeta?.path ?? "fhir/Questionnaire.item.repeats/time",
    meta: time0c1nbcBgZNMeta || {},
    alias: time0c1nbcBgZNMeta?.alias || [],
    redirect: time0c1nbcBgZNMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/time.vue").then(m => m.default || m)
  },
  {
    name: translationjDZEBZ9fJiMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.repeats-translation",
    path: translationjDZEBZ9fJiMeta?.path ?? "fhir/Questionnaire.item.repeats/translation",
    meta: translationjDZEBZ9fJiMeta || {},
    alias: translationjDZEBZ9fJiMeta?.alias || [],
    redirect: translationjDZEBZ9fJiMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.repeats/translation.vue").then(m => m.default || m)
  },
  {
    name: booleanvFEXeFRbviMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.required-boolean",
    path: booleanvFEXeFRbviMeta?.path ?? "fhir/Questionnaire.item.required/boolean",
    meta: booleanvFEXeFRbviMeta || {},
    alias: booleanvFEXeFRbviMeta?.alias || [],
    redirect: booleanvFEXeFRbviMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/boolean.vue").then(m => m.default || m)
  },
  {
    name: choiceZ2cz73EL6qMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.required-choice",
    path: choiceZ2cz73EL6qMeta?.path ?? "fhir/Questionnaire.item.required/choice",
    meta: choiceZ2cz73EL6qMeta || {},
    alias: choiceZ2cz73EL6qMeta?.alias || [],
    redirect: choiceZ2cz73EL6qMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/choice.vue").then(m => m.default || m)
  },
  {
    name: date7N7ztVmv0sMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.required-date",
    path: date7N7ztVmv0sMeta?.path ?? "fhir/Questionnaire.item.required/date",
    meta: date7N7ztVmv0sMeta || {},
    alias: date7N7ztVmv0sMeta?.alias || [],
    redirect: date7N7ztVmv0sMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/date.vue").then(m => m.default || m)
  },
  {
    name: dateTimeaj914ZQ2d2Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.required-dateTime",
    path: dateTimeaj914ZQ2d2Meta?.path ?? "fhir/Questionnaire.item.required/dateTime",
    meta: dateTimeaj914ZQ2d2Meta || {},
    alias: dateTimeaj914ZQ2d2Meta?.alias || [],
    redirect: dateTimeaj914ZQ2d2Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/dateTime.vue").then(m => m.default || m)
  },
  {
    name: decimalLVDPbxqA46Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.required-decimal",
    path: decimalLVDPbxqA46Meta?.path ?? "fhir/Questionnaire.item.required/decimal",
    meta: decimalLVDPbxqA46Meta || {},
    alias: decimalLVDPbxqA46Meta?.alias || [],
    redirect: decimalLVDPbxqA46Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/decimal.vue").then(m => m.default || m)
  },
  {
    name: implant_45table_46trueVVdK6yoUO5Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.required-implant-table.true",
    path: implant_45table_46trueVVdK6yoUO5Meta?.path ?? "fhir/Questionnaire.item.required/implant-table.true",
    meta: implant_45table_46trueVVdK6yoUO5Meta || {},
    alias: implant_45table_46trueVVdK6yoUO5Meta?.alias || [],
    redirect: implant_45table_46trueVVdK6yoUO5Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/implant-table.true.vue").then(m => m.default || m)
  },
  {
    name: integerLeeIF8LHllMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.required-integer",
    path: integerLeeIF8LHllMeta?.path ?? "fhir/Questionnaire.item.required/integer",
    meta: integerLeeIF8LHllMeta || {},
    alias: integerLeeIF8LHllMeta?.alias || [],
    redirect: integerLeeIF8LHllMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/integer.vue").then(m => m.default || m)
  },
  {
    name: open_45choiceEobLsyGE8AMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.required-open-choice",
    path: open_45choiceEobLsyGE8AMeta?.path ?? "fhir/Questionnaire.item.required/open-choice",
    meta: open_45choiceEobLsyGE8AMeta || {},
    alias: open_45choiceEobLsyGE8AMeta?.alias || [],
    redirect: open_45choiceEobLsyGE8AMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/open-choice.vue").then(m => m.default || m)
  },
  {
    name: quantitydivseUfCLsMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.required-quantity",
    path: quantitydivseUfCLsMeta?.path ?? "fhir/Questionnaire.item.required/quantity",
    meta: quantitydivseUfCLsMeta || {},
    alias: quantitydivseUfCLsMeta?.alias || [],
    redirect: quantitydivseUfCLsMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/quantity.vue").then(m => m.default || m)
  },
  {
    name: spinettVUiiTrT0Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.required-spine",
    path: spinettVUiiTrT0Meta?.path ?? "fhir/Questionnaire.item.required/spine",
    meta: spinettVUiiTrT0Meta || {},
    alias: spinettVUiiTrT0Meta?.alias || [],
    redirect: spinettVUiiTrT0Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/spine.vue").then(m => m.default || m)
  },
  {
    name: stringFNBZvHfWUZMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.required-string",
    path: stringFNBZvHfWUZMeta?.path ?? "fhir/Questionnaire.item.required/string",
    meta: stringFNBZvHfWUZMeta || {},
    alias: stringFNBZvHfWUZMeta?.alias || [],
    redirect: stringFNBZvHfWUZMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/string.vue").then(m => m.default || m)
  },
  {
    name: textsnHzRKfkYUMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.required-text",
    path: textsnHzRKfkYUMeta?.path ?? "fhir/Questionnaire.item.required/text",
    meta: textsnHzRKfkYUMeta || {},
    alias: textsnHzRKfkYUMeta?.alias || [],
    redirect: textsnHzRKfkYUMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/text.vue").then(m => m.default || m)
  },
  {
    name: time0fvBxtfHjEMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.required-time",
    path: time0fvBxtfHjEMeta?.path ?? "fhir/Questionnaire.item.required/time",
    meta: time0fvBxtfHjEMeta || {},
    alias: time0fvBxtfHjEMeta?.alias || [],
    redirect: time0fvBxtfHjEMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.required/time.vue").then(m => m.default || m)
  },
  {
    name: booleangPLqQHIj8gMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.type-boolean",
    path: booleangPLqQHIj8gMeta?.path ?? "fhir/Questionnaire.item.type/boolean",
    meta: booleangPLqQHIj8gMeta || {},
    alias: booleangPLqQHIj8gMeta?.alias || [],
    redirect: booleangPLqQHIj8gMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/boolean.vue").then(m => m.default || m)
  },
  {
    name: choicezrarut8uCSMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.type-choice",
    path: choicezrarut8uCSMeta?.path ?? "fhir/Questionnaire.item.type/choice",
    meta: choicezrarut8uCSMeta || {},
    alias: choicezrarut8uCSMeta?.alias || [],
    redirect: choicezrarut8uCSMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/choice.vue").then(m => m.default || m)
  },
  {
    name: dateq1TFO868uoMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.type-date",
    path: dateq1TFO868uoMeta?.path ?? "fhir/Questionnaire.item.type/date",
    meta: dateq1TFO868uoMeta || {},
    alias: dateq1TFO868uoMeta?.alias || [],
    redirect: dateq1TFO868uoMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/date.vue").then(m => m.default || m)
  },
  {
    name: dateTimetdmfxDzS24Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.type-dateTime",
    path: dateTimetdmfxDzS24Meta?.path ?? "fhir/Questionnaire.item.type/dateTime",
    meta: dateTimetdmfxDzS24Meta || {},
    alias: dateTimetdmfxDzS24Meta?.alias || [],
    redirect: dateTimetdmfxDzS24Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/dateTime.vue").then(m => m.default || m)
  },
  {
    name: decimal88LWw0CU42Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.type-decimal",
    path: decimal88LWw0CU42Meta?.path ?? "fhir/Questionnaire.item.type/decimal",
    meta: decimal88LWw0CU42Meta || {},
    alias: decimal88LWw0CU42Meta?.alias || [],
    redirect: decimal88LWw0CU42Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/decimal.vue").then(m => m.default || m)
  },
  {
    name: displayjEQYjusLP7Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.type-display",
    path: displayjEQYjusLP7Meta?.path ?? "fhir/Questionnaire.item.type/display",
    meta: displayjEQYjusLP7Meta || {},
    alias: displayjEQYjusLP7Meta?.alias || [],
    redirect: displayjEQYjusLP7Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/display.vue").then(m => m.default || m)
  },
  {
    name: integerA990pMuCnPMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.type-integer",
    path: integerA990pMuCnPMeta?.path ?? "fhir/Questionnaire.item.type/integer",
    meta: integerA990pMuCnPMeta || {},
    alias: integerA990pMuCnPMeta?.alias || [],
    redirect: integerA990pMuCnPMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/integer.vue").then(m => m.default || m)
  },
  {
    name: open_45choiceH3eOkEmdPKMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.type-open-choice",
    path: open_45choiceH3eOkEmdPKMeta?.path ?? "fhir/Questionnaire.item.type/open-choice",
    meta: open_45choiceH3eOkEmdPKMeta || {},
    alias: open_45choiceH3eOkEmdPKMeta?.alias || [],
    redirect: open_45choiceH3eOkEmdPKMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/open-choice.vue").then(m => m.default || m)
  },
  {
    name: quantity3KstzXZVc5Meta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.type-quantity",
    path: quantity3KstzXZVc5Meta?.path ?? "fhir/Questionnaire.item.type/quantity",
    meta: quantity3KstzXZVc5Meta || {},
    alias: quantity3KstzXZVc5Meta?.alias || [],
    redirect: quantity3KstzXZVc5Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/quantity.vue").then(m => m.default || m)
  },
  {
    name: stringpkqU1F6CQmMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.type-string",
    path: stringpkqU1F6CQmMeta?.path ?? "fhir/Questionnaire.item.type/string",
    meta: stringpkqU1F6CQmMeta || {},
    alias: stringpkqU1F6CQmMeta?.alias || [],
    redirect: stringpkqU1F6CQmMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/string.vue").then(m => m.default || m)
  },
  {
    name: textT53VPCaxYLMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.type-text",
    path: textT53VPCaxYLMeta?.path ?? "fhir/Questionnaire.item.type/text",
    meta: textT53VPCaxYLMeta || {},
    alias: textT53VPCaxYLMeta?.alias || [],
    redirect: textT53VPCaxYLMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/text.vue").then(m => m.default || m)
  },
  {
    name: timevwoEUIRoniMeta?.name ?? "@visiontree-mfx-auto-renderer-fhir-Questionnaire.item.type-time",
    path: timevwoEUIRoniMeta?.path ?? "fhir/Questionnaire.item.type/time",
    meta: timevwoEUIRoniMeta || {},
    alias: timevwoEUIRoniMeta?.alias || [],
    redirect: timevwoEUIRoniMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/fhir/Questionnaire.item.type/time.vue").then(m => m.default || m)
  },
  {
    name: summaryPJJbye4iTdMeta?.name ?? "@visiontree-mfx-auto-renderer-layouts-default-summary",
    path: summaryPJJbye4iTdMeta?.path ?? "layouts/default/summary",
    meta: summaryPJJbye4iTdMeta || {},
    alias: summaryPJJbye4iTdMeta?.alias || [],
    redirect: summaryPJJbye4iTdMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/layouts/default/summary.vue").then(m => m.default || m)
  },
  {
    name: autorenderer_45plugin_45exampleiLm2p5d0D2Meta?.name ?? "@visiontree-mfx-auto-renderer-plugins-autorenderer-plugin-example",
    path: autorenderer_45plugin_45exampleiLm2p5d0D2Meta?.path ?? "plugins/autorenderer-plugin-example",
    meta: autorenderer_45plugin_45exampleiLm2p5d0D2Meta || {},
    alias: autorenderer_45plugin_45exampleiLm2p5d0D2Meta?.alias || [],
    redirect: autorenderer_45plugin_45exampleiLm2p5d0D2Meta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer/plugins/autorenderer-plugin-example.vue").then(m => m.default || m)
  }
],
    meta: mfx_45auto_45rendererZcDSoDBRdAMeta || {},
    alias: mfx_45auto_45rendererZcDSoDBRdAMeta?.alias || [],
    redirect: mfx_45auto_45rendererZcDSoDBRdAMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/mfx-auto-renderer.vue").then(m => m.default || m)
  },
  {
    name: vue_45uiQkWTAIpb3VMeta?.name ?? "@visiontree-vue-ui",
    path: vue_45uiQkWTAIpb3VMeta?.path ?? "/@visiontree/vue-ui",
    meta: vue_45uiQkWTAIpb3VMeta || {},
    alias: vue_45uiQkWTAIpb3VMeta?.alias || [],
    redirect: vue_45uiQkWTAIpb3VMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/@visiontree/vue-ui.vue").then(m => m.default || m)
  },
  {
    name: indexuyUvLKiSgkMeta?.name ?? "index",
    path: indexuyUvLKiSgkMeta?.path ?? "/",
    meta: indexuyUvLKiSgkMeta || {},
    alias: indexuyUvLKiSgkMeta?.alias || [],
    redirect: indexuyUvLKiSgkMeta?.redirect || undefined,
    component: () => import("/Users/lenny.urbanowski/junk/vision-tree-mfx-framework/apps/examples/pages/index.vue").then(m => m.default || m)
  }
]